import React from "react";

const HomeHowItWorks = () => {
  return (
    <div className="how-it-works">
      <div className="container">
        <h2 className="text-center">How it works</h2>
        <h5 className="text-center">
          Discover why GottaHoliday is perfect for planning & booking your dream
          holiday.
          <br /> Let's look at all the amazing benefits that await you!
        </h5>

        <div className="how-it-works-tag">
          <div>
            <h3 className="number">01</h3>
            <h4>Compare packages across multiple destinations in one place.</h4>
          </div>
          <div>
            <h3 className="number">02</h3>
            <h4>Filter packages by budget, duration & other preferences.</h4>
          </div>
          <div>
            <h3 className="number">03</h3>
            <h4>
              Review itinerary and all the other details to zero in on your
              favorite package.
            </h4>
          </div>
          <div>
            <h3 className="number">04</h3>
            <h4>
              Connect with our experts on Whats App to customize and book the
              package.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHowItWorks;
