import React from "react";
import WhyUsImage from "../assets/images-new/why-us-img.png";
import Icon1 from "../assets/images-new/why-us-ico-1.svg";
import Icon2 from "../assets/images-new/why-us-ico-2.svg";
import Icon3 from "../assets/images-new/why-us-ico-3.svg";
import Icon4 from "../assets/images-new/why-us-ico-4.svg";
import Icon5 from "../assets/images-new/why-us-ico-5.svg";
import Icon6 from "../assets/images-new/why-us-ico-6.svg";

const HomeWhyTripDarwin = () => {
  return (
    <div className="why-trip-darwin">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img
              src={WhyUsImage}
              alt="Why Gottaholiday"
              className="why-us-image"
            />
          </div>
          <div className="col-lg-6">
            <div className="why-trip-darwin-cont">
              <h2>Why Gottaholiday</h2>
              <h5>
                Discover why GottaHoliday is perfect for planning & booking your
                dream holiday. Let's look at all the amazing benefits that await
                you!
              </h5>
              <div className="why-trip-darwin-scroll">
                <div className="box-bg">
                  <img src={Icon1} alt="Global Destinations" />
                  <div>
                    <h3>Global Destinations</h3>
                    <p>
                      Explore trips worldwide easily: Discover holiday packages
                      from countless destinations without the need to decide
                      upfront. Your holiday - simplified.
                    </p>
                  </div>
                </div>
                <div className="box-bg">
                  <img src={Icon2} alt="Customize Your Journey" />
                  <div>
                    <h3>Customize Your Journey</h3>
                    <p>
                      Make your trip your own: We help you find packages
                      matching your travel needs & budget. Your personalized
                      holiday starts here!
                    </p>
                  </div>
                </div>
                <div className="box-bg">
                  <img src={Icon3} alt="Human Connection" />
                  <div>
                    <h3>Human Connection</h3>
                    <p>
                      Connect for personal help: Gottaholiday links online and
                      offline smoothly. Talk to experts for personalized travel
                      advice and support.
                    </p>
                  </div>
                </div>
                <div className="box-bg">
                  <img src={Icon4} alt="Fast Assistance" />
                  <div>
                    <h3>Fast Assistance</h3>
                    <p>
                      Quick responses: Gottaholiday answers faster than most. No
                      more waiting, just fast help with your questions and
                      travel needs.
                    </p>
                  </div>
                </div>
                <div className="box-bg">
                  <img src={Icon5} alt="Transparent Pricing" />
                  <div>
                    <h3>Transparent Pricing</h3>
                    <p>
                      Clear costs, no surprises: We ensure transparent pricing
                      with clear breakdowns. Enjoy your vacation without
                      unexpected charges.
                    </p>
                  </div>
                </div>
                <div className="box-bg">
                  <img src={Icon6} alt="In-trip Assistance" />
                  <div>
                    <h3>In-trip Assistance</h3>
                    <p>
                      Travel confidently with Gottaholiday by your side! Whether
                      you need recommendations, changes, or in-trip support,
                      we'll be there for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWhyTripDarwin;
