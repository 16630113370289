import React from "react";
import { useNavigate } from "react-router-dom";

import Destination1 from "../assets/images-new/destination-singapore.png";
import Destination2 from "../assets/images-new/destination-bali.png";
import Destination3 from "../assets/images-new/destination-thailand.png";
import Destination4 from "../assets/images-new/destination-switzerland.png";
import Destination5 from "../assets/images-new/destination-france.png";
import Destination6 from "../assets/images-new/destination-turkey.png";
import Destination7 from "../assets/images-new/destination-greece.png";
import Destination8 from "../assets/images-new/destination-azerbaijan.png";
import Destination9 from "../assets/images-new/destination-uk.png";
import Destination10 from "../assets/images-new/destination-egypt.png";
import Destination11 from "../assets/images-new/destination-malaysia.png";

const HomeTrending = () => {
  const navigate = useNavigate();

  const handleExplore = (destination) => {
    let query = "";

    switch (destination) {
      case "Singapore":
        query = "Singapore-country-59eea4812f94933910ea3ff8";
        break;

      case "Bali":
        query = "Bali-city-5a055c3b9f1e3826a473e30d";
        break;

      case "Thailand":
        query = "Thailand-country-59eea4812f94933910ea3fec";
        break;

      case "Switzerland":
        query = "Switzerland-country-59eea4822f94933910ea4077";
        break;

      case "France":
        query = "France-country-59eea4822f94933910ea405e";
        break;

      case "Turkey":
        query = "Turkey-country-59eea4812f94933910ea3fe8";
        break;

      case "Greece":
        query = "Greece-country-59eea4822f94933910ea4053";
        break;

      case "Azerbaijan (Baku)":
        query = "Azerbaijan-country-59eea4822f94933910ea408c";
        break;

      case "UK":
        query = "UK-country-59eea4822f94933910ea405c";
        break;

      case "Egypt":
        query = "Egypt-country-59eea4822f94933910ea4064";
        break;

      case "Malaysia":
        query = "Malaysia-country-59eea4812f94933910ea401a";
        break;

      default:
        break;
    }

    navigate("/trip-ideas/destination-" + query);
  };

  const handleCategory = (category) => {
    navigate("/trip-ideas/category-" + category);
  };
  return (
    <div className="home-trending">
      <div className="container">
        <div className="home-title">
          <h3>Choose as per your need</h3>
          <h2>Trending Destinations</h2>
        </div>

        <div className="home-trending-tabs">
          <ul className="list-unstyled p-0 m-0 d-flex align-items-center">
            <li>
              <button className="active" onClick={() => handleExplore("JAPAN")}>
                All
              </button>
            </li>
            <li>
              <button onClick={() => handleCategory("Adventurous")}>
                Adventurous
              </button>
            </li>
            <li>
              <button onClick={() => handleCategory("Kids friendly")}>
                Kids friendly
              </button>
            </li>
            <li>
              <button onClick={() => handleCategory("Couple")}>Couple</button>
            </li>
            <li>
              <button onClick={() => handleCategory("Honeymoon")}>
                Honeymoon
              </button>
            </li>
            <li>
              <button onClick={() => handleCategory("Elderly friendly")}>
                Elderly friendly
              </button>
            </li>
            <li>
              <button onClick={() => handleCategory("Historical")}>
                Historical
              </button>
            </li>
            <li>
              <button onClick={() => handleCategory("Cultural")}>
                Cultural
              </button>
            </li>
          </ul>
        </div>

        <div className="home-trending-row mt-4">
          <div className="home-trending-col-2">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="home-trending-img-1"
                  onClick={() => handleExplore("Singapore")}
                >
                  <img src={Destination1} alt="" />
                  <div className="home-trending-content">
                    <span>Mountain View</span>
                    <h3>Singapore</h3>
                    <h4>4 Nights, 5 Days</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="home-trending-img-1"
                  onClick={() => handleExplore("Bali")}
                >
                  <img src={Destination2} alt="" />
                  <div className="home-trending-content">
                    <span>Mountain View</span>
                    <h3>Bali</h3>
                    <h4>6 Nights, 7 Days</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="home-trending-img-1"
                  onClick={() => handleExplore("Thailand")}
                >
                  <img src={Destination3} alt="" />
                  <div className="home-trending-content">
                    <span>Mountain View</span>
                    <h3>Thailand</h3>
                    <h4>3 Nights, 4 Days</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-trending-col-1">
            <div
              className="home-trending-img-2"
              onClick={() => handleExplore("Switzerland")}
            >
              <img src={Destination4} alt="" />
              <div className="home-trending-content">
                <span>Mountain View</span>
                <h3>Switzerland</h3>
                <h4>7 Nights, 8 Days</h4>
              </div>
            </div>
          </div>
          <div className="home-trending-col-2">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="home-trending-img-1"
                  onClick={() => handleExplore("France")}
                >
                  <img src={Destination5} alt="" />
                  <div className="home-trending-content">
                    <span>Mountain View</span>
                    <h3>France</h3>
                    <h4>10 Nights, 11 Days</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="home-trending-img-1"
                  onClick={() => handleExplore("Turkey")}
                >
                  <img src={Destination6} alt="" />
                  <div className="home-trending-content">
                    <span>Mountain View</span>
                    <h3>Turkey</h3>
                    <h4>5 Nights, 6 Days</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="home-trending-img-1"
                  onClick={() => handleExplore("Greece")}
                >
                  <img src={Destination7} alt="" />
                  <div className="home-trending-content">
                    <span>Mountain View</span>
                    <h3>Greece</h3>
                    <h4>12 Nights, 13 Days</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-trending-col-1">
            <div
              className="home-trending-img-1"
              onClick={() => handleExplore("Azerbaijan (Baku)")}
            >
              <img src={Destination8} alt="" />
              <div className="home-trending-content">
                <span>Mountain View</span>
                <h3>Azerbaijan (Baku)</h3>
                <h4>3 Nights, 4 Days</h4>
              </div>
            </div>
          </div>
          <div className="home-trending-col-2">
            <div
              className="home-trending-img-1"
              onClick={() => handleExplore("UK")}
            >
              <img src={Destination9} alt="" />
              <div className="home-trending-content">
                <span>Mountain View</span>
                <h3>UK</h3>
                <h4>9 Nights, 10 Days</h4>
              </div>
            </div>
          </div>
          <div className="home-trending-col-1">
            <div
              className="home-trending-img-1"
              onClick={() => handleExplore("Egypt")}
            >
              <img src={Destination10} alt="" />
              <div className="home-trending-content">
                <span>Mountain View</span>
                <h3>Egypt</h3>
                <h4>8 Nights, 9 Days</h4>
              </div>
            </div>
          </div>
          <div className="home-trending-col-1">
            <div
              className="home-trending-img-1"
              onClick={() => handleExplore("Malaysia")}
            >
              <img src={Destination11} alt="" />
              <div className="home-trending-content">
                <span>Mountain View</span>
                <h3>Malaysia</h3>
                <h4>4 Nights, 5 Days</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTrending;
