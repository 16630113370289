import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

import SliderThumb1 from "../assets/images-new/slider-thumb-1.png";
import SliderThumb2 from "../assets/images-new/slider-thumb-2.png";
import SliderThumb3 from "../assets/images-new/slider-thumb-3.png";

const HomeBanner = () => {
  const navigate = useNavigate();

  const swiperRef = useRef();

  const handleExplore = (destination) => {
    let query = "";

    switch (destination) {
      case "Japan":
        query = "Japan-country-59eea4822f94933910ea4041";
        break;

      case "Switzerland":
        query = "Switzerland-country-59eea4822f94933910ea4077";
        break;

      case "Finland":
        query = "Finland-country-59eea4822f94933910ea4062";
        break;

      default:
        break;
    }

    navigate("/trip-ideas/destination-" + query);
  };

  return (
    <div className="home-slider">
      <Swiper
        slidesPerView={1}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        modules={[Pagination, Autoplay]}
        breakpoints={{
          769: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
        }}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            const thumbImages = [SliderThumb1, SliderThumb2, SliderThumb3];
            return `
              <span class="${className}">
                <img src="${thumbImages[index]}" alt="thumb" />
              </span>
            `;
          },
        }}
        autoplay={{
          delay: 9000,
          disableOnInteraction: false,
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="home-slider-img home-slider-img-1">
            <div className="container">
              <div className="home-slider-info">
                <h2>Snow in winter</h2>
                <h3>Train journey</h3>
                <h4>7 Nights 8 Days</h4>
                <button onClick={() => handleExplore("Switzerland")}>
                  Know More
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="home-slider-img home-slider-img-2">
            <div className="container">
              <div className="home-slider-info">
                <h2>Northern lights</h2>
                <h3>Finland</h3>
                <h4>8 Nights 9 Days</h4>
                <button onClick={() => handleExplore("Finland")}>
                  Know More
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="home-slider-img home-slider-img-3">
            <div className="container">
              <div className="home-slider-info">
                <h2>Cherry Blossom</h2>
                <h3>Japan</h3>
                <h4>6 Nights 7 Days</h4>
                <button onClick={() => handleExplore("Japan")}>
                  Know More
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeBanner;
