import React, { useState } from "react";
import GottaholidayLogoFooter from "../assets/images-new/gottaholiday-logo-footer.png";
import { Link } from "react-router-dom";
import fetchData from "../services/requester";
import Membership1 from "../assets/images/footer-membership-tafi.png";
import Membership2 from "../assets/images/footer-membership-trai.png";
import Icon1 from "../assets/images/icon-linkedin.png";
import Icon2 from "../assets/images/icon-twitter.png";
import Icon3 from "../assets/images/icon-facebook.png";
import Icon4 from "../assets/images/icon-instagram.png";
import Icon5 from "../assets/images/icon-youtube.png";

const Footer = (section) => {
  const [data, setData] = useState({
    fullName: "Anonymous Anonymous",
    address: "",
    contactNumber: "9900000000",
    query: "Sign up to Newsletter",
  });
  const [validation, setValidation] = useState({
    address: false,
  });
  const [updateKey, setUpdateKey] = useState(1);
  const [isSubmited, setSubmited] = useState(false);

  const scroll = (pos) => {
    const section = document.querySelector(pos);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onChange = (e) => {
    if (e.target.name === "address") {
      data.address = e.target.value;
    }
    setData(data);
  };

  const emailValid = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleValidation = () => {
    if (!data?.address) {
      validation.address = !data?.address ? true : false;
      setValidation(validation);
      setUpdateKey(updateKey + 1);
    } else {
      if (!emailValid(data?.address)) {
        validation.address = !emailValid(data?.address) ? true : false;
        setValidation(validation);
        setUpdateKey(updateKey + 1);
      } else {
        handleSubmit();
      }
    }
  };

  const handleSubmit = () => {
    let req = "faq/submitDetails";
    let obj = {
      data,
    };

    fetchData(req, obj, (res) => {
      data.address = "";
      setData(data);
      setSubmited(true);
    });
  };

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-newsletter g-lg-5 d-none">
              <div className="row">
                <div className="col-lg-4">
                  <h3>Sign up to our Newsletter</h3>
                  <p>
                    Subscribe to our newsletter for travel ideas, exciting
                    deals, and the latest travel updates directly in your inbox.
                  </p>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-7">
                  <div className="footer-newsletter-form">
                    <input
                      name="address"
                      id="address"
                      type="text"
                      placeholder="ABC@gmail.com"
                      className="form-control"
                      onChange={onChange}
                    />
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleValidation}
                    >
                      Join the mailing list
                    </button>
                  </div>
                  {validation?.address && (
                    <p
                      style={{
                        margin: "8px 0px 0px 0px",
                        color: "#ffb010",
                      }}
                      key={updateKey}
                    >
                      Please enter valid email.
                    </p>
                  )}
                  {isSubmited && (
                    <p
                      style={{
                        margin: "8px 0px 0px 0px",
                        color: "#ffb010",
                      }}
                      key={updateKey}
                    >
                      Successfully subscribed up to our Newsletter!
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="footer-about">
              <div className="row">
                <div className="col-lg-4">
                  <Link to="/" className="logo-footer">
                    <img src={GottaholidayLogoFooter} alt="gottaholiday.com" />
                  </Link>
                  <p>
                    Begin your travel journey with Gottaholiday. Plan holidays
                    the way you want, book at great prices and get reliable
                    support throughout your trip.
                  </p>
                </div>
                <div className="col-lg-8">
                  <div className="row g-lg-5">
                    <div className="col-lg-3">
                      <h4>About us</h4>
                      <Link to="/about-us">About Us</Link>
                      <Link to="/" onClick={() => scroll(".why-trip-darwin")}>
                        Why Gottaholiday
                      </Link>
                      <Link onClick={() => scroll(".how-it-works")} to="/">
                        How it works
                      </Link>
                      <Link
                        to="https://gottaholiday.com/blogs/"
                        target="_blank"
                      >
                        Blogs
                      </Link>
                      <Link
                        to="https://gottaholiday.com/blogs/explore-destinations/"
                        target="_blank"
                      >
                        Explore Destinations
                      </Link>
                    </div>
                    <div className="col-lg-3">
                      <h4>Support</h4>
                      <Link to="/contact-us">Contact us</Link>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </div>
                    <div className="col-lg-6">
                      <h4>Follow us</h4>
                      <div className="footer-social-icons">
                        <Link
                          to="https://www.linkedin.com/company/darwin-tech/"
                          target="_blank"
                        >
                          <img src={Icon1} alt="linkedin" />
                        </Link>
                        <Link
                          to="https://twitter.com/gottaholiday/"
                          target="_blank"
                        >
                          <img src={Icon2} alt="twitter" />
                        </Link>
                        <Link
                          to="https://www.facebook.com/Darwin-Tech-485432294979199/"
                          target="_blank"
                        >
                          <img src={Icon3} alt="facebook" />
                        </Link>
                        <Link
                          to="https://www.instagram.com/gottaholiday/"
                          target="_blank"
                        >
                          <img src={Icon4} alt="instagram" />
                        </Link>
                        <Link
                          to="https://www.youtube.com/channel/UC46guW0V9zhp_pr7Yhz6brA"
                          target="_blank"
                        >
                          <img src={Icon5} alt="youtube" />
                        </Link>
                      </div>
                      <h4>Memberships</h4>
                      <div className="footer-membership">
                        <img src={Membership1} alt="TAFI" />
                        <img src={Membership2} alt="TRAI" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
