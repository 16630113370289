import React from "react";
import Icon1 from "../assets/images-new/coming-soon-icon-1.png";
import Icon2 from "../assets/images-new/coming-soon-icon-2.png";
import Icon3 from "../assets/images-new/coming-soon-icon-3.png";
import Icon4 from "../assets/images-new/coming-soon-icon-4.png";
import Icon5 from "../assets/images-new/coming-soon-icon-5.png";
import Icon6 from "../assets/images-new/coming-soon-icon-6.png";

const HomeComingSoon = () => {
  return (
    <div className="home-coming">
      <div className="container">
        <div className="home-coming-wrap">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
              <div className="home-coming-title">
                <h2>What's Coming Soon?</h2>
                <p>
                  We have new places to explore, different ways to travel, and
                  opportunities for fun reunions. <br />
                  <br />
                  Exciting updates are coming to make your travels more
                  enjoyable!
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="home-coming-bg">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="wrap-box">
                      <div className="icon-wrap">
                        <img src={Icon1} alt="Bucket List" />
                      </div>
                      <div>
                        <h3>Bucket List</h3>
                        <p>
                          Tick-off Must-Visit Spots on Your Holiday Checklist!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="wrap-box mb-4">
                      <div className="icon-wrap">
                        <img src={Icon2} alt="New Destinations" />
                      </div>
                      <div>
                        <h3>New Destinations</h3>
                        <p>Discover new places & experience new cultures</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="wrap-box">
                      <div className="icon-wrap">
                        <img src={Icon3} alt="New way to explore" />
                      </div>
                      <div>
                        <h3>New way to explore</h3>
                        <p>Discover new places to visit in popular countries</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="wrap-box">
                      <div className="icon-wrap">
                        <img src={Icon4} alt="Reunion Travel" />
                      </div>
                      <div>
                        <h3>Reunion Travel</h3>
                        <p>
                          Plan memorable trips with old buddies ahead of time!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="wrap-box">
                      <div className="icon-wrap">
                        <img src={Icon5} alt="Shopping travel" />
                      </div>
                      <div>
                        <h3>Shopping travel</h3>
                        <p>
                          Shop and explore new places to indulge in some retail
                          therapy!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="wrap-box">
                      <div className="icon-wrap">
                        <img src={Icon6} alt="Events & Concerts travel" />
                      </div>
                      <div>
                        <h3>Experience travel</h3>
                        <p>
                          Enjoy the excitement of concerts and events across the
                          globe!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComingSoon;
