import React, { useState } from "react";
import GottaholidayLogo from "../assets/images/gottaholiday-logo.png";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";

const Header = () => {
  const [isMobile, setMobile] = useState(false);

  const handleMobileMenu = () => {
    setMobile(!isMobile);
  };
  return (
    <StickyBox offsetTop={0} offsetBottom={0} style={{ zIndex: "1000000" }}>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 d-flex align-items-center">
              <Link to="/" className="logo">
                <img src={GottaholidayLogo} alt="gottaholiday.com" />
              </Link>
            </div>

            <div className="col-lg-9 d-flex justify-content-end align-items-center">
              <button
                className="mobile-menu-btn d-none"
                onClick={handleMobileMenu}
              >
                <span className="material-symbols-outlined">|||</span>
              </button>

              <div className={"navbar" + (isMobile ? " show-mobile-menu" : "")}>
                <ul className="list-unstyled p-0 m-0 d-flex align-items-center">
                  <li>
                    <Link to="about-us" style={{ marginLeft: "16px" }}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="highlight"
                      to="/trip-ideas"
                      onClick={handleMobileMenu}
                    >
                      Trip Ideas
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="currency-selector">₹</div>
              <button className="header-menu-icon" />
            </div>
          </div>
        </div>
      </header>
    </StickyBox>
  );
};

export default Header;
