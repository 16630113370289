import React from "react";

const HomeTroughEyes = () => {
  return (
    <div className="home-trought-eyes">
      <div className="container">
        <div className="home-title">
          <h3>Discover</h3>
          <h2>The World through our eyes</h2>
        </div>
      </div>
    </div>
  );
};

export default HomeTroughEyes;
