import React, { useState } from "react";
import fetchData from "../services/requester";
import NewsletterImg from "../assets/images-new/newsletter-img.png";

const HomeNewsletter = () => {
  const [data, setData] = useState({
    fullName: "Anonymous Anonymous",
    address: "",
    contactNumber: "9900000000",
    query: "Sign up to Newsletter",
  });
  const [validation, setValidation] = useState({
    address: false,
  });
  const [updateKey, setUpdateKey] = useState(1);
  const [isSubmited, setSubmited] = useState(false);

  const scroll = (pos) => {
    const section = document.querySelector(pos);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onChange = (e) => {
    if (e.target.name === "address") {
      data.address = e.target.value;
    }
    setData(data);
  };

  const emailValid = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleValidation = () => {
    if (!data?.address) {
      validation.address = !data?.address ? true : false;
      setValidation(validation);
      setUpdateKey(updateKey + 1);
    } else {
      if (!emailValid(data?.address)) {
        validation.address = !emailValid(data?.address) ? true : false;
        setValidation(validation);
        setUpdateKey(updateKey + 1);
      } else {
        handleSubmit();
      }
    }
  };

  const handleSubmit = () => {
    let req = "faq/submitDetails";
    let obj = {
      data,
    };

    fetchData(req, obj, (res) => {
      data.address = "";
      setData(data);
      setSubmited(true);
    });
  };

  return (
    <div className="home-newsletter">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <img src={NewsletterImg} alt=" Subscribe to get information" />
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <div>
              <h2>
                Subscribe to get information, and the latest travel updates
                directly in your inbox.
              </h2>

              <div>
                <div className="newsletter-form">
                  <input
                    name="address"
                    id="address"
                    type="text"
                    placeholder="ABC@gmail.com"
                    className="form-control"
                    onChange={onChange}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleValidation}
                  >
                    Subscribe
                  </button>
                </div>
                {validation?.address && (
                  <p
                    style={{
                      margin: "8px 0px 0px 0px",
                      color: "#ffb010",
                    }}
                    key={updateKey}
                  >
                    Please enter valid email.
                  </p>
                )}
                {isSubmited && (
                  <p
                    style={{
                      margin: "8px 0px 0px 0px",
                      color: "#ffb010",
                    }}
                    key={updateKey}
                  >
                    Successfully subscribed up to our Newsletter!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNewsletter;
