import React, { useState, useEffect } from "react";
import fetchData from "../services/requester-live";

const ResultFilterscategories = (props) => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isHide, setShowHide] = useState(true);
  const [checkKey, setCheckKey] = useState(1);

  const getData = () => {
    let req = "bundles/getReadyTripCategories";
    let obj = "";
    fetchData(
      req,
      obj,
      (data) => {
        setData(data || []);
        if (props?.filters?.categories?.length > 0) {
          setCategories(props?.filters?.categories || []);
          setShowHide(false);
        }
      },
      "GET"
    );
  };

  const handleShowHide = () => {
    setShowHide(!isHide);
  };

  const onChange = (e) => {
    let category = categories;
    let current = e?.target?.value;
    if (category.includes(current)) {
      category = category.filter((x) => x !== current);
    } else {
      category.push(current);
    }
    setCheckKey(checkKey + 1);
    setCategories(category);
    props.handleFilters("categories", category);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="filters-categories">
      {console.log(categories)}
      <h4>Categories</h4>
      <div className={"filters-categories-" + (isHide ? "hide" : "show")}>
        <ul className="list-unstyled">
          {data &&
            data?.map((x, key) => (
              <li key={key}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={x}
                    id={"id" + key}
                    name={"id" + key}
                    onChange={onChange}
                    checked={categories.includes(x) ? true : false}
                    key={checkKey}
                  />
                  <label className="form-check-label" htmlFor={"id" + key}>
                    {x}
                  </label>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <button className="btn btn-sm btn-link" onClick={handleShowHide}>
        Show {isHide ? "More" : "Less"}
      </button>
    </div>
  );
};

export default ResultFilterscategories;
